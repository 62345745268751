"use client";

import clsx from "clsx";
import type { ReactNode } from "react";
import { ErrorMessage } from "@/components/ErrorMessage/ErrorMessage.component";
import { Link } from "@/components/Link/Link.component";
import { List } from "@/components/List/List.component";
import { ListItem } from "@/components/List/ListItem.component";
import { ProductSection } from "@/components/Paywall/AddTrialDescription/_components/ProductSection/ProductSection.component";
import { Spinner } from "@/components/Spinner/Spinner.component";
import { useSite } from "@/contexts/site/site.context";
import type { ErrorMessageStatus, Fetchable } from "@/services/utilities/fetchable";
import { isPending, hasFailed } from "@/services/utilities/fetchable";
import type { PaywallModel } from "../models/paywall.model";
type AddTrialDescriptionProps = {
  readonly addTrialButton: ReactNode;
  readonly className?: string;
  readonly model: PaywallModel;
  readonly submissionState: Fetchable;
};
export function AddTrialDescription({
  addTrialButton,
  className,
  model,
  submissionState
}: AddTrialDescriptionProps) {
  const {
    locale,
    name: siteName
  } = useSite();
  const {
    articleId,
    usedTrial,
    numberOfFreeTrialDays,
    individualSubscription,
    addTrial,
    defaultShopProduct
  } = model;
  const hasShopEnabled = defaultShopProduct !== null && articleId !== null;
  const hasLegacyShopEnabled = defaultShopProduct === null && individualSubscription !== null;
  const shopSection = hasShopEnabled ? <ProductSection priceWithCurrency={defaultShopProduct.priceWithCurrency} url={`/shop/checkout/billing-details?articleId=${articleId}&productId=${defaultShopProduct.id}`} /> : null;
  const legacyShopSection = hasLegacyShopEnabled ? <ProductSection priceWithCurrency={individualSubscription.pricePerQuarter} url={individualSubscription.shopUrl} /> : null;
  return <article className={clsx(className, "relative border border-solid border-silver lg:mr-2.5")} data-sentry-component="AddTrialDescription" data-sentry-source-file="AddTrialDescription.component.tsx">
      <div className="relative flex flex-col gap-y-2 bg-whisper p-2 lg:px-9 lg:pb-7 lg:pt-8">
        <h1 className="hyphens-manual break-words border-b border-solid border-silver pb-2 font-heading text-3xl font-theme-bold lg:text-4xl">
          {{
          da: `Du er logget ind, men du er ikke tilknyttet et aktivt abonnement på ${siteName}`,
          de: `Sie sind angemeldet, aber mit keinem aktiven Abonnement für ${siteName} verbunden`,
          en: `You are logged in, but you do not have an active subscription to ${siteName}`,
          no: `Du er logget inn, men du er ikke tilknyttet et aktivt abonnement på ${siteName}`,
          sv: `Du är inlogg, men du har inte en aktiv prenumeration på ${siteName}`
        }[locale]}
        </h1>
        <List className="space-y-1" type="bullet" data-sentry-element="List" data-sentry-source-file="AddTrialDescription.component.tsx">
          {usedTrial.isTrialUsed || hasShopEnabled ? null : <ListItem>
              {{
            da: `Prøv ${siteName} i ${numberOfFreeTrialDays} dage - og få adgang til alt indhold.`,
            de: `Testen Sie ${siteName} ${numberOfFreeTrialDays} Tage lang - und erhalten Sie Zugang zu allen Inhalten.`,
            en: `Try ${siteName} for ${numberOfFreeTrialDays} days - and get access to all content.`,
            no: `Prøv ${siteName} i ${numberOfFreeTrialDays} dager - og få tilgang til alt innhold.`,
            sv: `Prova ${siteName} i ${numberOfFreeTrialDays} dagar - och få tillgång till allt innehåll.`
          }[locale]}{" "}
              {addTrialButton}
            </ListItem>}
          <ListItem data-sentry-element="ListItem" data-sentry-source-file="AddTrialDescription.component.tsx">
            {{
            da: "Ønsker du et prøveabonnement med flere adgange til dig og din arbejdsplads?",
            de: "Möchten Sie ein Probeabonnement mit mehreren Lizenzen für Ihr Unternehmen?",
            en: "Do you want a trial subscription with multiple users for yourself and your colleagues?",
            no: "Ønsker du et prøveabonnement med flere brukertilganger for deg og din bedrift?",
            sv: "Vill du ha en testprenumeration med flera användare för dig och ditt företag?"
          }[locale]}{" "}
            <Link className="text-primary hover:underline" href={addTrial.salesTeamUrl} data-sentry-element="Link" data-sentry-source-file="AddTrialDescription.component.tsx">
              {{
              da: "Læs mere om mulighederne og find kontaktoplysninger på vores salgsafdeling her.",
              de: "Erfahren Sie mehr über die Möglichkeiten. Unser Vertrieb freut sich auf Ihre Anfrage.",
              en: "Read more about your options and find the contact information to our sales team here.",
              no: "Les mer om mulighetene og finn kontaktopplysninger til vår salgsavdeling her.",
              sv: "Läs mer om dina alternativ och hitta kontaktinformation till vårt säljteam här."
            }[locale]}
            </Link>
          </ListItem>
        </List>

        {isPending(submissionState) ? <div className={clsx("absolute left-0 top-0 z-0 flex h-full w-full items-center justify-center", "after:absolute after:left-0 after:top-0 after:block after:h-full after:w-full after:bg-whisper after:opacity-70")}>
            <Spinner className="z-10 h-6 w-6 fill-nobel text-silver" />
          </div> : null}

        {hasFailed(submissionState) ? <ErrorMessage status={(submissionState.errorMessage as ErrorMessageStatus)} /> : null}
      </div>

      {shopSection !== null ? <div className="divide-y divide-solid divide-silver border-t border-silver bg-white px-2 lg:px-9 lg:py-4 lg:pb-2">
          {shopSection}
        </div> : null}

      {legacyShopSection !== null ? <div className="divide-y divide-solid divide-silver border-t border-silver bg-white px-2 lg:px-9 lg:py-4 lg:pb-2">
          {legacyShopSection}
        </div> : null}
    </article>;
}