"use client";

import { useReCaptcha } from "next-recaptcha-v3";
import { useState } from "react";
import { createProfileWithTrial } from "@/app/(sites)/_containers/_actions/create-profile-with-trial";
import { fetchUserExists } from "@/app/_api/user/fetch-user-exists";
import { CreateProfileLogoutLoginButton } from "@/components/Button/components/CreateProfileLogoutLoginButton/CreateProfileLogoutLoginButton.component";
import { FullWidthCreateProfileSubmitButton } from "@/components/Button/components/CreateProfileSubmitButton/FullWidthCreateProfileSubmitButton.component";
import { CreateUserForm } from "@/components/CreateUserForm/CreateUserForm.component";
import type { CreateUserFormData } from "@/components/CreateUserForm/CreateUserForm.component";
import { CreateTrialDescription } from "@/components/Paywall/CreateTrialDescription/CreateTrialDescription.component";
import type { PaywallModel } from "@/components/Paywall/models/paywall.model";
import { SuccessBox } from "@/components/Paywall/SuccessBox/SuccessBox.component";
import { SwitchFetchable } from "@/components/SwitchFetchable/SwitchFetchable.component";
import { useSite } from "@/contexts/site/site.context";
import { conversionEvents } from "@/hooks/tracking/kilkaya/klikaya.types";
import type { Fetchable } from "@/services/utilities/fetchable";
import { failed, initial, succeeded } from "@/services/utilities/fetchable";
type PaywallCreateTrialContainerProps = {
  readonly className?: string;
  readonly model: PaywallModel;
};
export function PaywallCreateTrialContainer({
  className,
  model
}: PaywallCreateTrialContainerProps) {
  const {
    domain,
    locale,
    id: siteId
  } = useSite();
  const [containerState, setContainerState] = useState<Fetchable>(initial());
  const {
    executeRecaptcha
  } = useReCaptcha();
  const submit = async (data: CreateUserFormData) => {
    const recaptchaToken = await executeRecaptcha(`${domain.replace(".", "_")}_paywall_create_trial`);
    const response = await createProfileWithTrial({
      site: siteId,
      ...data,
      recaptchaToken
    });
    const isSuccessful: boolean = response.status.includes("CREATED");
    const newStatus = isSuccessful ? succeeded() : failed(response.status);
    setContainerState(newStatus);
    return newStatus;
  };
  async function validateEmail(email: string) {
    const recaptchaToken = await executeRecaptcha(`${domain.replace(".", "_")}_create_profile_email_validation`);
    const userExists = await fetchUserExists(email, recaptchaToken);
    return userExists ? failed("USER_ALREADY_EXISTS") : succeeded();
  }
  return <SwitchFetchable fetchable={containerState} renderDefault={() => <CreateTrialDescription createTrialForm={<CreateUserForm SubmitButton={FullWidthCreateProfileSubmitButton} termsType="subscription-terms" onEmailValidation={validateEmail} onSubmit={submit} />} model={model} />} renderSucceeded={() => <SuccessBox appStoreUrl={model.apps.appStoreUrl} className={className} conversionEventTags={conversionEvents.createTrialArticleEvent} ctaButton={<CreateProfileLogoutLoginButton className="mt-2">
              {{
      da: "Log ind og læs artiklen",
      de: "Einloggen und Artikel lesen",
      en: "Log in and read the article",
      no: "Logg inn og les artikkelen",
      sv: "Logga in och läs artikeln"
    }[locale]}
            </CreateProfileLogoutLoginButton>} googlePlayUrl={model.apps.googlePlayUrl} />} data-sentry-element="SwitchFetchable" data-sentry-component="PaywallCreateTrialContainer" data-sentry-source-file="PaywallCreateTrialContainer.component.tsx" />;
}