import { clsx } from "clsx";
import type { ArticleTeaserModel } from "@/app/(sites)/_models/article-teaser-model.types";
type ArticleTeaserHeadingProps = {
  readonly className?: string;
  readonly title: ArticleTeaserModel.HtmlAndText;
};
export function ArticleTeaserHeading({
  className,
  title: {
    kicker,
    html
  }
}: ArticleTeaserHeadingProps) {
  return <h1 className={clsx("hyphens-manual break-words font-heading", className)} data-k5a-pos="head" data-sentry-component="ArticleTeaserHeading" data-sentry-source-file="ArticleTeaserHeading.component.tsx">
      {kicker !== null ? <span className="text-primary">{kicker} </span> : null}
      <span dangerouslySetInnerHTML={{
      __html: html
    }} />
    </h1>;
}