"use client";

import { useRouter } from "next/navigation";
import type { FormEvent } from "react";
import { useState } from "react";
import { addTrial } from "@/app/(sites)/_containers/_actions/add-trial";
import { SubmitButton } from "@/components/Button/components/SubmitButton/SubmitButton.component";
import { AddTrialDescription } from "@/components/Paywall/AddTrialDescription/AddTrialDescription.component";
import type { PaywallModel } from "@/components/Paywall/models/paywall-model.types";
import { SuccessBox } from "@/components/Paywall/SuccessBox/SuccessBox.component";
import { SwitchFetchable } from "@/components/SwitchFetchable/SwitchFetchable.component";
import { useSite } from "@/contexts/site/site.context";
import { useUser } from "@/contexts/user/user.context";
import { conversionEvents } from "@/hooks/tracking/kilkaya/klikaya.types";
import type { Fetchable } from "@/services/utilities/fetchable";
import { failed, initial, isPending, pending, succeeded } from "@/services/utilities/fetchable";
type PaywallAddTrialContainerProps = {
  readonly articleId: string;
  readonly className?: string;
  readonly model: PaywallModel;
};
export function PaywallAddTrialContainer({
  articleId,
  className,
  model
}: PaywallAddTrialContainerProps) {
  const router = useRouter();
  const {
    locale
  } = useSite();
  const {
    model: userModel
  } = useUser();
  const [containerState, setContainerState] = useState<Fetchable>(initial());
  const [refreshArticleState, setRefreshArticleState] = useState<Fetchable>(initial());
  async function trialClick() {
    setContainerState(pending());
    if (!userModel.loggedIn) {
      return failed("NOT_LOGGED_IN");
    }
    const result = await addTrial({
      articleId
    });
    setContainerState(result.status !== "CREATED" ? failed(result.status) : succeeded());
  }
  function refreshPage(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setRefreshArticleState(pending());
    router.refresh();
  }
  return <SwitchFetchable fetchable={containerState} renderDefault={() => <AddTrialDescription addTrialButton={<button className="text-left text-primary hover:underline" type="submit" onClick={trialClick}>
              {{
      da: "Få et uforpligtende prøveabonnement her.",
      de: "Erhalten Sie hier ein unverbindliches Probeabonnement.",
      en: "Get a non-binding trial subscription here.",
      no: "Få et uforpliktende prøveabonnement her.",
      sv: "Få ett icke-bindande provabonnemang här."
    }[locale]}
            </button>} model={model} submissionState={containerState} />} renderSucceeded={() => <SuccessBox appStoreUrl={model.apps.appStoreUrl} className={className} conversionEventTags={conversionEvents.addTrialArticleEvent} ctaButton={<SubmitButton className="mt-2 w-full" isPending={isPending(refreshArticleState)}>
              {{
      da: "Klik her og læs artiklen",
      de: "Klicken Sie hier, um den Artikel zu lesen",
      en: "Click here to read the article",
      no: "Klikk her for å lese artikkelen",
      sv: "Klicka här för att läsa artikeln"
    }[locale]}
            </SubmitButton>} googlePlayUrl={model.apps.googlePlayUrl} onSubmit={refreshPage} />} data-sentry-element="SwitchFetchable" data-sentry-component="PaywallAddTrialContainer" data-sentry-source-file="PaywallAddTrialContainer.component.tsx" />;
}